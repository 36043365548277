export const testListApi = '/list'
export const testStoreApi = '/store'
export const testUpdateApi = '/update'
export const testToggleStatusApi = '/toggle-status'

// farmer request api list
export const farmerRequestList = '/warehouse-service-farmer-req-entry/list'
export const farmerRequestStore = '/warehouse-service-farmer-req-entry/store'
export const farmerRequestUpdate = '/warehouse-service-farmer-req-entry/update'
export const farmerRequestShow = '/warehouse-service-farmer-req-entry/show'
export const farmerRequestToggleStatus = '/warehouse-service-farmer-req-entry/toggle-status'
export const farmerRequestListDestroy = '/warehouse-service-farmer-req-entry/destroy'
export const farmerRequestCheckList = '/master-check-list/list-all'
export const farmerRequestReject = '/warehouse-service-farmer-req-entry/reject'
export const farmerRequestApprove = '/warehouse-service-farmer-req-entry/approve'

// wareservice  list
export const wareserviceRegistrationList = '/wareservice-registration/list'
export const wareserviceRegistrationStore = '/wareservice-registration/store'
export const wareserviceRegistrationUpdate = '/wareservice-registration/update'

// Registration Recommendation
export const RegistrationRecommendationList = '/warehouseUser-recommendation/list'
export const RegRecomChangeStatus = '/warehouseUser-recommendation/changeStatus'
export const RegRecomDetails = '/warehouseUser-recommendation/details'
export const RegRecomDetailsList = '/warehouseUser-recommendation/detailsList'
// payment germplasm-collection api list
export const paymentCollectionList = '/warehouse-service-payment-collection/list'
export const paymentCollectionStore = '/warehouse-service-payment-collection/store'
export const paymentCollectionToggleStatus = '/warehouse-service-payment-collection/toggle-status'

//  delivery schedule list
export const reportHeadingList = '/master-warehouse-report-heading/list'
export const deliveryScheduleList = '/warehouse-service-report/delivery-schedule-list'

// Registration Approve
export const RegApproveStatus = '/registration-approve/approveStatus'
export const RegRejectStatus = '/registration-approve/RejectStatus'
export const recommendationList = '/registration-approve/list'

export const spaceAvailabilityDashboardApi = '/warehouse-service/space-availability-dashboard'

// auth farmer list
export const farmerList = '/user/farmer-list'
export const farmerProfile = '/user/farmer-profile/'

// qc cirtificate for farmer request
export const wareFarmerQCCertificateShowDetails = '/qc-certificate/details'

// user notification api
export const sendNotification = '/user-notification/send-notification'
