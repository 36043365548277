<template>
    <b-container>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <div class="text-center">
                            <h5 class="card-title"><i class="ri-edit-line"></i> {{ $t('globalTrans.personal_information') }}</h5>
                        </div>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <form-input :data="formInputData" @change="setData" @submit="register">
                                <template v-slot:submitButton="">
                                    <b-button-group>
                                        <b-button type="submit" variant="primary"
                                                class="mr-1 rounded"
                                                title="Draft copy will be saved, you can edit this anytime."
                                                @click="isFinal = false">{{ $t('globalTrans.save_draft') }}
                                            <i class="far fa-save ml-1"></i>
                                        </b-button>
                                        <b-button type="submit"
                                                variant="danger"
                                                title="Final Save cannot be reverted!"
                                                class="ml-1 rounded"
                                                @click="isFinal = true">{{ $t('globalTrans.finalSave') }}
                                            <i class="fas fa-lock ml-1"></i>
                                        </b-button>
                                    </b-button-group>
                                </template>
                            </form-input>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import RestApi, { authServiceBaseUrl, warehouseServiceBaseUrl } from '@/config/api_config'
import { wareFarmerProfileUpdate, wareFarmerProfileStore } from '../../../api/routes'
import FormInput from '@/components/structure/form/validationForm/FormInput'
import { Common } from '@/mixins/helper-functions'
import { EventBus } from '@/EventBusLayout'
import { sendNotification } from '@/modules/warehouse-service/ware-service/api/routes'

export default {
    mixin: [Common],
    components: {
        FormInput
    },
    created () {
        this.getFarmer()
        // const tmp = this.getData()
        // if (tmp.id) {
        //     this.id = parseInt(tmp.id)
        //     Object.freeze(tmp)
        //     Object.assign(this.farmerBasicInfo, tmp)
        //     this.save_status = this.farmerBasicInfo.save_status
        //     if ([1, 3].includes(this.$store.state.ExternalUserIrrigation.farmerWareInfo.status)) {
        //         return this.$router.push('/warehouse-farmer/profile')
        //     }
        // }
        const authItem = {
          name: this.authUser.name,
          name_bn: this.authUser.name_bn,
          email: this.authUser.email,
          mobile_no: this.authUser.mobile_no
        }
        this.setData(authItem)
        // this.farmerBasicInfo = Object.assign({}, this.farmerBasicInfo, authItem)
    },
    data () {
        return {
            isFinal: false,
            farmerBasicInfo: {
                name: '',
                name_bn: '',
                mobile_no: '',
                region_id: '',
                division_id: '',
                district_id: '',
                upazilla_id: '',
                union_id: '',
                warehouse_type_id: '',
                save_status: 1,
                warehouse_id: ''
            },
            districtList: [],
            upazilaList: [],
            unionList: [],
            id: '',
            loading: false,
            warehouseNameList: [],
            landTypeList: [
                { text: 'Own', value: 1 },
                { text: 'Lease', value: 2 }
            ]
        }
    },
    computed: {
        divisionList () {
            return this.$store.state.ExternalUserIrrigation.commonObj.divisionList
        },
        formInputData () {
            return {
                chunk: true,
                buttonLocale: this.id ? 'globalTrans.update' : 'globalTrans.save_application_form',
                buttonClass: 'btn btn-primary btn-block',
                dataParent: this.farmerBasicInfo,
                data: [
                    {
                        head: {
                            title: 'externalUserIrrigation.farmer_info'
                        },
                        items: [
                            {
                                name: 'name',
                                type: 'Input',
                                title: 'Name',
                                label: 'globalTrans.name',
                                readOnly: true
                            },
                            {
                                name: 'name_bn',
                                type: 'Input',
                                title: 'Name Bn',
                                label: 'globalUserData.name_bn',
                                readOnly: true
                            },
                            {
                                name: 'mobile_no',
                                type: 'Input',
                                title: 'Mobile',
                                label: 'user.phone_no',
                                readOnly: true
                            },
                            {
                                name: 'nid',
                                type: 'Input',
                                title: 'NID',
                                inputType: 'number',
                                label: 'externalUserIrrigation.nid'
                            },
                            {
                                name: 'father_name',
                                type: 'Input',
                                title: 'Father Name',
                                label: 'externalUserIrrigation.father_name'
                            },
                            {
                                name: 'father_name_bn',
                                type: 'Input',
                                title: 'Father Name (Bn)',
                                label: 'externalUserIrrigation.father_name_bn'
                            },
                            {
                                name: 'service_type',
                                type: 'Input',
                                title: 'Beneficiary Type',
                                label: 'externalUserIrrigation.beneficiary_type'
                            },
                            {
                                name: 'service_type_bn',
                                type: 'Input',
                                title: 'Beneficiary Type (Bn)',
                                label: 'externalUserIrrigation.beneficiary_type_bn'
                            },
                            {
                                name: 'land_type_id',
                                type: 'Select',
                                title: 'Land Type',
                                label: 'externalUserIrrigation.land_type',
                                options: this.landTypeList,
                                rules: 'required|min_value:1'
                            },
                            {
                                name: 'land_area',
                                type: 'Input',
                                title: 'Land Area',
                                inputType: 'number',
                                max: 5,
                                min: 0,
                                label: 'externalUserIrrigation.land_area'
                            }
                        ]
                    },
                    {
                        head: {
                            title: 'externalUserIrrigation.address'
                        },
                        items: [
                            {
                                name: 'division_id',
                                type: 'Select',
                                title: 'Division',
                                label: 'org_pro_division.division',
                                options: this.divisionList,
                                rules: 'required|min_value:1'
                            },
                            {
                                name: 'region_id',
                                type: 'Select',
                                title: 'Region',
                                label: 'externalUserIrrigation.region',
                                options: this.regionLists,
                                rules: 'required|min_value:1'
                            },
                            {
                                name: 'district_id',
                                type: 'Select',
                                title: 'District',
                                label: 'org_pro_district.district',
                                options: this.districtList
                            },
                            {
                                name: 'upazilla_id',
                                type: 'Select',
                                title: 'Upazila',
                                label: 'org_pro_upazilla.upazilla',
                                options: this.upazilaList
                            },
                            {
                                name: 'union_id',
                                type: 'Select',
                                title: 'Union',
                                label: 'org_pro_union.union',
                                options: this.unionList,
                                rules: 'nullable'
                            },
                             {
                                name: 'warehouse_type_id',
                                type: 'Select',
                                title: 'Warehouse Type',
                                label: 'warehouse_config.warehouse_type',
                                options: this.warehouseTypeList,
                                rules: 'required|min_value:1'
                            },
                            {
                                name: 'warehouse_id',
                                type: 'Select',
                                title: 'Warehouse',
                                label: 'warehouse_config.warehouse_name',
                                options: this.warehouseNameList,
                                rules: 'required|min_value:1'
                            },
                            {
                                name: 'address',
                                type: 'Input',
                                title: 'Address',
                                label: 'externalUserIrrigation.address'
                            },
                            {
                                name: 'address_bn',
                                type: 'Input',
                                title: 'Address (Bn)',
                                label: 'externalUserIrrigation.address_bn'
                            },
                            {
                                name: 'remarks',
                                type: 'Textarea',
                                title: 'Remarks',
                                label: 'externalUserIrrigation.remarks',
                                rules: 'nullable'
                            },
                            {
                                name: 'remarks_bn',
                                type: 'Textarea',
                                title: 'Remarks (Bn)',
                                label: 'externalUserIrrigation.remarks_bn',
                                rules: 'nullable'
                            }
                        ]
                    }
                ]
            }
        },
        regionLists () {
            return this.$store.state.ExternalUserIrrigation.regionList
        },
        warehouseTypeList () {
            const warehouseTypeList = this.$store.state.ExternalUserIrrigation.warehouse.warehouseTypeList
            return warehouseTypeList
        },
        commonProfile () {
            return this.$store.state.ExternalUserIrrigation.commonProfile
        },
        authUser () {
          return this.$store.state.Auth.authUser
        }
    },
    watch: {
        isFinal: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.farmerBasicInfo.save_status = newVal ? 2 : 1
            }
        },
        'farmerBasicInfo.region_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.districtList = this.getDistrictList(newVal)
            }
        },
        'farmerBasicInfo.district_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.upazilaList = this.getUpazilaList(newVal)
            }
        },
        'farmerBasicInfo.upazilla_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.unionList = this.getUnionList(newVal)
                this.getWarehouseName()
            }
        },
        'farmerBasicInfo.union_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getWarehouseName()
            }
        },
        'farmerBasicInfo.warehouse_type_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getWarehouseName()
            }
        }
    },
    methods: {
        setData (data) {
            this.farmerBasicInfo = Object.assign(this.farmerBasicInfo, data)
        },
        getData () {
            return this.$store.state.ExternalUserIrrigation.farmerWareInfo
        },
        async sendNotification (notificationData) {
          const response = await RestApi.postData(warehouseServiceBaseUrl, sendNotification, notificationData)
          if (response.success) {
            // this.$socket.emit('send-notification', response.notification)
          }
        },
        async register () {
            let result = null
            this.loading = true
            // const loadingState = { loading: true, listReload: false }
            // this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', loadingState)
            result = await RestApi.putData(authServiceBaseUrl, `${wareFarmerProfileUpdate}`, this.farmerBasicInfo)
            if (result.success) {
                if (result.notification_data.message) {
                  this.sendNotification(result.notification_data)
                }
                if (result.admin_notification_data.message) {
                  this.sendNotification(result.admin_notification_data)
                }
                this.$toast.success({
                title: 'Success',
                message: this.farmerBasicInfo.id ? 'Data updated successfully' : 'Data save successfully',
                color: '#D6E09B'
                })
            } else {
                this.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
                })
            }
            // this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: true })
            this.loading = false
            if (result.success === true) {
              // this.$socket.emit('send-notification', result.notification)
              // this.$socket.emit('send-notification', result.admin_notification)
              EventBus.$emit('EXTERNAL_USER_COMMON_PROFILE')
              // this.getFarmer()
              setTimeout(async () => {
                  await this.$router.push('/warehouse-farmer/profile')
              }, 1000)
            }
        },
        getDistrictList (regionId = null) {
            const regionDistrictList = this.$store.state.ExternalUserIrrigation.regionDistrictList
            let districts = []
            if (regionDistrictList) {
                const filtered = regionDistrictList.filter(item => item.region_id === regionId)
                if (filtered) {
                    districts = filtered.map((item) => {
                        return {
                            text: item.district_text,
                            value: item.district_id
                        }
                    })
                }
            }
            return districts
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList

            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        getUnionList (upazilaId = null) {
            const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList
            if (upazilaId) {
                return unionList.filter(union => union.upazilla_id === upazilaId)
            }
            return unionList
        },
        getWarehouseName () {
            const unionId = this.farmerBasicInfo.union_id
            const typeId = this.farmerBasicInfo.warehouse_type_id
            const upazilaId = this.farmerBasicInfo.upazilla_id
            const warehouseList = this.$store.state.ExternalUserIrrigation.warehouse.warehouseInfoList
            let data = []
            if (upazilaId > 0) {
                data = warehouseList.filter(item => item.upazilla_id === upazilaId)
            }
            if (unionId > 0) {
                data = data.filter(item => item.union_id === unionId)
            }
            if (typeId > 0) {
                data = data.filter(item => item.warehouse_type_id === typeId)
            }
            this.warehouseNameList = data
            return data
        },
        async getFarmer () {
            try {
                this.loading = true
                const result = await RestApi.getData(authServiceBaseUrl, wareFarmerProfileStore)
                if (result.success) {
                    // const data = result.data
                    // if (!result.nid) {
                    //     Object.assign(data, this.getCommonProfile())
                    // }
                    // this.$store.dispatch('ExternalUserIrrigation/getfarmerWareInfoDetails', result.data)
                  const tmp = result.data
                  Object.freeze(tmp)
                  this.farmerBasicInfo = Object.assign({}, this.farmerBasicInfo, tmp)
                }
            } catch (error) {
                this.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
                })
            }
            this.loading = false
        },
        getCommonProfile () {
            const tmp = {
                name: this.commonProfile.name,
                name_bn: this.commonProfile.name_bn,
                mobile_no: this.commonProfile.mobile_no
            }
            if (this.commonProfile.father_name !== null && this.commonProfile.father_name !== undefined) {
                tmp.father_name = this.commonProfile.father_name
            }
            if (this.commonProfile.father_name_bn !== null && this.commonProfile.father_name_bn !== undefined) {
                tmp.father_name_bn = this.commonProfile.father_name_bn
            }
            if (this.commonProfile.nid !== null && this.commonProfile.nid !== undefined) {
                tmp.nid = this.commonProfile.nid
            }
            return tmp
        }
    }
}
</script>
